import React from 'react';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

import PanelDetailsHeader from './components/PanelDetailsHeader';
import PanelDetailsParent from './components/PanelDetailsParent';
import PanelDetailsHistory from './components/PanelDetailsHistory';
import { UserT } from 'business/definitions';

export type ParentDetailsMapping = {
    translation: string;
    value: string;
};

export type PanelDetailsEvent = {
    id: number;
    lastItem?: boolean;
    date: string;
    user: Pick<UserT, 'email'> | null;
    type: string;
};

type Props = {
    onClose: () => void;
    open: boolean;
    valuesToDisplay: ParentDetailsMapping[];
    headerTitle: string;
    events: PanelDetailsEvent[];
    getEventComponent: (event: PanelDetailsEvent) => React.ReactElement;
    commentField?: React.ReactElement;
    overview?: React.ReactElement;
    parentTitle?: string;
    reverseParentBold?: boolean;
    drawerWidth?: string;
};

const StyDrawer = styled(Drawer, {
    name: 'StyDrawer',
    shouldForwardProp: (prop) => prop !== 'drawerWidth',
})<{ drawerWidth: string }>(({ drawerWidth }) => ({
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        maxWidth: '700px',
    },
    /* with MUI5, Modal have a default higher z-index than the drawer (1300 vs 1200);
     * details panel is therefore hidden without this override */
    zIndex: 1400,
}));

export default function PanelDetails({
    open,
    onClose,
    valuesToDisplay,
    headerTitle,
    events,
    getEventComponent,
    commentField = undefined,
    overview = undefined,
    parentTitle = undefined,
    reverseParentBold = false,
    drawerWidth = '35%',
}: Props): React.ReactElement {
    const theme = useTheme();
    return (
        <StyDrawer
            anchor="right"
            open={open}
            onClose={onClose}
            drawerWidth={drawerWidth}
        >
            <Box sx={{ margin: theme.spacing(5) }}>
                <PanelDetailsHeader title={headerTitle} onClose={onClose} />
                {overview}
                <PanelDetailsParent
                    valuesToDisplay={valuesToDisplay}
                    title={parentTitle}
                    reverseBold={reverseParentBold}
                />
                {events.length > 0 && (
                    <PanelDetailsHistory
                        events={events}
                        getEventComponent={getEventComponent}
                        commentField={commentField}
                    />
                )}
            </Box>
        </StyDrawer>
    );
}
