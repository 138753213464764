import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ParentDetailsMapping } from '../PanelDetails';

type Props = {
    valuesToDisplay: ParentDetailsMapping[];
    title?: string;
    reverseBold?: boolean;
};

const StyValueBlock = styled(Box, {
    name: 'StyValueBlock',
})(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
    alignItems: 'center',
}));

export default function PanelDetailsParent({
    valuesToDisplay,
    title = undefined,
    reverseBold = false,
}: Props): React.ReactElement {
    const theme = useTheme();
    return (
        <Box
            sx={{
                mt: theme.spacing(3),
            }}
        >
            {title && <Typography variant="body1Bold">{title}</Typography>}
            <Box
                sx={{
                    mt: theme.spacing(2),
                }}
            >
                {valuesToDisplay.map((value) => (
                    <StyValueBlock key={value.translation}>
                        <Typography
                            sx={{
                                ...(reverseBold
                                    ? { fontSize: '12px' }
                                    : { fontWeight: 600 }),
                            }}
                        >
                            {value.translation}
                        </Typography>
                        <Typography
                            sx={{
                                ...(reverseBold
                                    ? {
                                          fontSize: '12px',
                                          fontWeight: 600,
                                          color: theme.palette.primary.main,
                                      }
                                    : {}),
                            }}
                        >
                            {value.value}
                        </Typography>
                    </StyValueBlock>
                ))}
            </Box>
        </Box>
    );
}
