import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';

import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import NoDataState from 'ui/NoDataState/NoDataState';
import PanelDetailsHistoryItem from './PanelDetailsHistoryItem';
import { PanelDetailsEvent } from '../PanelDetails';

type Props = {
    events: PanelDetailsEvent[];
    getEventComponent: (event: PanelDetailsEvent) => React.ReactElement;
    commentField?: React.ReactElement;
};

const StyTimeline = styled(Timeline, {
    name: 'StyTimeline',
})({
    marginBottom: 0,
    paddingBottom: 0,
    paddingTop: 0,
});

export default function PanelDetailsHistory({
    events,
    getEventComponent,
    commentField,
}: Props): React.ReactElement {
    const { t } = useTranslation();
    const theme = useTheme();

    if (!events) return <NoDataState />;

    if (events && events.length > 0) {
        events.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
        );
        events[events.length - 1].lastItem = true;
    }

    const timeline = (
        <StyTimeline position="right">
            {commentField}
            {events &&
                events.length > 0 &&
                events.map((event) => (
                    <PanelDetailsHistoryItem
                        key={event.id}
                        event={event}
                        getEventComponent={getEventComponent}
                    />
                ))}
        </StyTimeline>
    );

    return (
        <Box sx={{ marginTop: theme.spacing(3) }}>
            <Typography variant="body1Bold">{t('common.history')}</Typography>
            {timeline}
        </Box>
    );
}
